import fp from "lodash/fp";
import {useAppSelector} from "@/core/store/hooks";


export const useAuthFormErrors = () => {
	return useAppSelector(
		(state) => {
			const error = state.auth.states?.user?.message || null;

			return error ? {
				type: 'backend',
				message: error,
			} : undefined;
		},
		fp.isEqual,
	)
}
