import {Typography} from "@/components/base/Typography";
import {useTranslation} from "react-i18next";
import {DivideHalf} from "@/components/wrappers/DivideHalf";
import {Input} from "@/components/form/Input";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "@/core/store/hooks";
import {business} from "@/business";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useAuthFormErrors} from "@/components/views/auth/login/hooks";
import {Checkbox} from "@/components/form/Checkbox";

import clsx from "clsx";
import {Button} from "@/components/base/Button";

import {LinkButton} from "@/components/base/LinkButton";
import styles from './styles.module.scss';
import {useEffect} from "react";
import {LoadingStatus} from "@/core/store/utils/sagas";
import i18next from "i18next";
import {Spin} from "@/components/icons/Spin";

const schema = yup.object().shape({
    username: yup.string()
        .required(() => i18next.t('common.errors.yup.common.required'))
        .default(''),
    password: yup.string()
        .required(() => i18next.t('common.errors.yup.common.required'))
        .default(''),
});

type AuthSchema = yup.InferType<typeof schema>

export const LoginView = () => {
    const {t} = useTranslation();

    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(business.auth.actions.clearMeta('user'))
        }
    }, [dispatch]);

    const errors = useAuthFormErrors();

    const loading = useAppSelector(
        (state) => state.auth.states?.user?.status === LoadingStatus.Loading,
    );

    const methods = useForm<AuthSchema>({
        mode: 'onSubmit',
        defaultValues: schema.getDefault(),
        resolver: yupResolver(schema),
    })

    useEffect(() => methods.watch(() => methods.clearErrors('root')).unsubscribe, [methods])

    useEffect(() => {
        if (errors) methods.setError('root', errors)
    }, [errors, methods])

    const rootErr = methods.formState.errors.root?.message

    const onSubmit: SubmitHandler<AuthSchema> = (data) => dispatch(business.auth.actions.getToken(data))

    return (
        <DivideHalf
            rightType='Registration'
            left={(
                <div className='d-flex justify-content-center h-100'>
                    <FormProvider {...methods}>
                        <form
                            onSubmit={methods.handleSubmit(onSubmit)}
                            className={clsx('d-flex flex-column justify-content-lg-center', styles.authForm)}
                        >
                            <img
                                className={clsx('align-self-center d-lg-none', styles.banner)}
                                src='/imgs/auth/AuthBanner.svg'
                                alt='banner'
                            />
                            <Typography
                                type={'H3Header'}
                                typesWithMedia={['H1Headermd']}
                                color='brand-primary'
                            >
                                {t('authPage.in')}
                            </Typography>
                            <Input
                                name='username'
                                autoComplete='username email'
                                label={t('authPage.email.label')}
                                placeholder={t('authPage.email.placeholder')}
                            />
                            <Input
                                name='password'
                                type='password'
                                autoComplete='current-password'
                                label={t('authPage.password.label')}
                                placeholder={t('authPage.password.placeholder')}
                            />
                            <Checkbox label={t('authPage.kavo')} name='memoUser'/>
                            <div className='d-flex flex-column'>
                                <Button
                                    htmlType='submit'
                                    disabled={!methods.formState.isValid || loading}
                                >
                                    {loading && <Spin/>}
                                    {t('authPage.in')}
                                </Button>
                                {rootErr && (
                                    <Typography type='SmallLabel' color='red-primary' as='span'>
                                        {rootErr}
                                    </Typography>
                                )}
                            </div>
                            <div className='d-flex justify-content-between'>
                                <LinkButton
                                    href='/auth/registration'
                                >
                                    {t('authPage.registration')}
                                </LinkButton>
                                <LinkButton
                                    href='/auth/restorePass'
                                >
                                    {t('authPage.restorePass')}
                                </LinkButton>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            )}
            right={(
                <div className={clsx('align-items-center d-flex flex-column w-100', styles.right)}>
                    <img
                        className={styles.logo}
                        src='/imgs/logos/Logo_full.svg'
                        alt='logo'
                    />
                    <img
                        className={styles.banner}
                        src='/imgs/auth/AuthBanner.svg'
                        alt='banner'
                    />
                </div>
            )}/>
    )
}
