import clsx from 'clsx';
import styles from './styles.module.scss'
import React from "react";

interface DivideHalfProps {
	left: React.ReactNode;
	right?: React.ReactNode;
	rightType: 'Registration' | ''
}

export const DivideHalf = (
	{
		left,
		right = null,
		rightType,
	}: DivideHalfProps,
) => {
	return (
		<div className={styles.layout}>
			<div className={clsx(
				styles.left,
				'flex-fill',
			)}>
				{left}
			</div>
			<div className={clsx(
				styles.right,
				styles[`right${rightType}`],
				'd-none d-lg-flex',
			)}>
				{right}
			</div>
		</div>
	)
}
