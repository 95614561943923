import {LoginView} from "@/components/views/auth/login";
import {UnauthorizedHead} from "@/components/heads/UnauthorizedHead";
import {IAppConfigProps} from "@/utils/config";

const Login = (
	{
		config,
	}: { config: IAppConfigProps['storeConfig'] },
) => {
	return (
		<>
			<UnauthorizedHead config={config}/>
			<LoginView/>
		</>
	)
}

export default Login;
