import Head from "next/head";
import {IAppConfigProps} from "@/utils/config";

export const UnauthorizedHead = (
	{
		config,
	}: { config: IAppConfigProps['storeConfig'] },
) => (
	<Head>
		<title>{`АБухгалтерия${config.NEXT_PUBLIC_MOCK_DATA === 'true' ? ' MOCK SERVER' : ''}`}</title>
		<link rel="icon" href="/imgs/logos/Logo_mini.svg"/>
		<meta name="description" content="Бухгалтерия для ИП и ООО в вашем банке"/>
		<meta property="og:title" content="АБухгалтерия" key="title"/>
		<meta property="og:og:description" content="Бухгалтерия для ИП и ООО в вашем банке"/>
		<meta property="og:image" content={`${config.NEXT_PUBLIC_URI}/imgs/logos/Logo_mini.svg`}/>
		<meta property="og:logo" content={`${config.NEXT_PUBLIC_URI}/imgs/logos/Logo_mini.svg`}/>
		<meta property="og:url" content={config.NEXT_PUBLIC_URI}/>
		<meta property="og:type" content='website'/>
	</Head>
)